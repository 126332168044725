<script>
import { AxiosAPI } from '@/axios';
import routerpath from '../../router/routerpath';
import { useMainStore, useResponseStore } from '../../stores/index';
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';
import Viwerjs from './Modals/Viwerjs.vue';
import Breadcrumb from '../../components/documents/patchName.vue';
import SignerSituation from './AtomComponents/SignerSituation.vue';
import SignerPen from './AtomComponents/SignerPen.vue';
export default {
  name: 'DashboardDefault',
  components: {
    Viwerjs,
    Breadcrumb,
    QrcodeVue,
    SignerSituation,
    SignerPen
  },
  setup () {
    const StoreAll = useMainStore();
    const StoreDoc = useResponseStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      StoreResp: StoreDoc
    };
  },
  data () {
    return {
      data: this.$route.params.id,
      despacho: this.Store.viewDispatch,
      DocumentDespach: [],
      response_doc: false,
      documentos: [{ protocol: 'Aguarde', dispatch: '...', subject: 'Aguarde', user: [{ name: 'aguarde' }], status: '...', ip: '...', to_departments: [] }],
      logsAll: [],
      assunto: '',
      Departamentos: [],
      Servidor: [],
      Servidor_send: [],
      mod: [],
      attachments: [],
      tack: [],
      type_doc: 0,
      categories: [{ id: 1, name: 'PROCESSO ADMINISTRATIVO' }],
      Loading: true,
      DocumentView: true,
      editDocView: false,
      editDoc: false,
      EditData: [],
      hidden: true,
      componenteHeight: '93vh',
      textComent: '',
      valueCode: 'https://smartlegis.net.br/',
      sizeCode: 100,
      despachoPdf: '',
      despachoLog: [],
      progress: 0,
      SignerSucess: false,
      SignerAttachSucess: false,
      NameDocSigner: [],
      showPassword: false,
      wrongPasswords: false,
      password: '',
      existAttach: false,
      documentCompleteView: false,
      loadingComment: false,
      required_signers: false,
      requiredList: [],
      SignerWithAttachSucess: false
    };
  },
  mounted () {
    this.Get_doc_info();
    this.Get_users();
    this.checkComponentHeight();
    this.RefreshNubers();
    this.Store.GetNumbers();
    window.addEventListener('resize', this.checkComponentHeight);
    this.Store.EditDocumentSolo = false;
    this.Store.EditDocumentGroup = false;
    this.Store.EditDocumentSigners = false;
    this.Store.progressEdit = 1;
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('ViewDoc');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '93vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },

    RefreshNubers () {
      this.Store.GetNumbers();
    },

    async Get_doc_info () {
      const doc = [];
      const logsAll = [];
      await AxiosAPI
        .get('/api/documents?protocol=' + this.data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((response) => {
          response.data.documents.data.sort((a, b) => a.id - b.id);
          for (let i = 0; i < response.data.documents.data.length; i++) {
            doc.push(response.data.documents.data[i]);
          }
          this.documentos = doc;

          doc.forEach(documents => {
            logsAll.push(...documents.logs);
          });
          logsAll.sort((a, b) => a.id - b.id);
          this.logsAll = logsAll;

          this.Loading = false;
          doc.forEach(pdfs => {
            this.DocumentDespach = pdfs;
            if (pdfs.dispatch === this.despacho) {
              this.despachoPdf = this.Store.host + pdfs.complete_file;
              this.Store.pdfNow = this.despachoPdf;
              this.despachoLog = pdfs.logs;
              if (pdfs.attachments.length >= 1) {
                this.existAttach = true;
              } else {
                this.existAttach = false;
              }
              this.CheckEdit(pdfs);
            }
          });
        });

      await AxiosAPI
        .get('/api/documents/complete-file?protocol=' + this.data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((response) => {
          this.valueCode = response.data.file;
        });

      this.DespachoData();
    },
    AccordionChange (event) {
      const UserDepID = [];
      const DataRequiredSigners = [];
      if (event.index === 0) {
        this.despachoPdf = this.Store.host + this.valueCode;
        this.Store.pdfNow = this.despachoPdf;
        this.existAttach = false;
        this.response_doc = false;
        this.documentCompleteView = true;
      } else {
        this.documentCompleteView = false;
        this.despacho = event.index;

        this.documentos.forEach(pdfs => {
          if (pdfs.dispatch === this.despacho) {
            let answersDepartament = [];
            const answersLog = pdfs.logs
              .some(log => log.user_id === this.Store.user.id && log.type === '3');

            const answers = pdfs.to_users
              .some(user => user.user_id === this.Store.user.id);

            this.Store.user.departments.forEach(userDepartamentID => {
              UserDepID.push(userDepartamentID.id);
              const answersValidate = pdfs.to_departments.some(departament => UserDepID.includes(departament.department_id));
              answersDepartament = answersValidate;
            });

            if (pdfs.signers.some(requiredSigner => requiredSigner.required && requiredSigner.clicksign_status === '0')) {
              this.required_signers = true;
              pdfs.signers.forEach(signersRequired => {
                if (signersRequired.required) {
                  DataRequiredSigners.push(signersRequired);
                }
              });
            } else {
              this.required_signers = false;
            }
            if (answers === true && answersLog === false) {
              this.response_doc = true;
            } else if (answersDepartament === true && answersLog === false) {
              this.response_doc = true;
            } else {
              this.response_doc = false;
            }

            if (pdfs.attachments.length >= 1) {
              this.existAttach = true;
            } else {
              this.existAttach = false;
            }
            this.despachoPdf = this.Store.host + pdfs.complete_file;
            this.Store.pdfNow = this.despachoPdf;
            this.despachoLog = pdfs.logs;
            this.DocumentDespach = pdfs;
            this.CheckEdit(pdfs);
          }
        });
      }
      this.requiredList = DataRequiredSigners;
    },

    CheckEdit (value) {
      const lastArray = this.documentos[this.documentos.length - 1];

      if (lastArray.attachments_sign) {
        this.Store.signerAttach = 1;
      }

      if (!lastArray.attachments_sign) {
        this.Store.signerAttach = 0;
      }

      if (lastArray.signers.length >= 1) {
        const blockEdit = lastArray.signers
          .flatMap(signerList => signerList)
          .every(signer => signer.clicksign_status === '1');

        if (!blockEdit) {
          if (value.dispatch === lastArray.dispatch) {
            if (lastArray.author.user.id === this.Store.user.id) {
              this.editDoc = true;
            } else {
              this.editDoc = false;
            }
          } else {
            this.editDoc = false;
          }
        } else {
          this.editDoc = false;
        }

        const blockBySigner = lastArray.signers
          .flatMap(signerList => signerList)
          .some(signer => signer.clicksign_status === '1');
        this.Store.blockBySigner = blockBySigner;
      }

      if (lastArray.signers.length === 0) {
        if (value.dispatch === lastArray.dispatch) {
          if (lastArray.author.user.id === this.Store.user.id) {
            this.editDoc = true;
          } else {
            this.editDoc = false;
          }
        } else {
          this.editDoc = false;
        }
      }
    },

    DespachoData () {
      const UserDepID = [];
      const DataRequiredSigners = [];
      if (this.despacho === 0) {
        this.despacho = 1;
      }
      this.documentos.forEach(pdfs => {
        if (pdfs.dispatch === this.despacho) {
          let answersDepartament = [];
          const answersLog = pdfs.logs
            .some(log => log.user_id === this.Store.user.id && log.type === '3');

          const answers = pdfs.to_users
            .some(user => user.user_id === this.Store.user.id);

          this.Store.user.departments.forEach(userDepartamentID => {
            UserDepID.push(userDepartamentID.id);
            const answersValidate = pdfs.to_departments.some(departament => UserDepID.includes(departament.department_id));

            answersDepartament = answersValidate;
          });

          if (pdfs.signers.some(requiredSigner => requiredSigner.required && requiredSigner.clicksign_status === '0')) {
            this.required_signers = true;
            pdfs.signers.forEach(signersRequired => {
              if (signersRequired.required) {
                DataRequiredSigners.push(signersRequired);
              }
            });
          } else {
            this.required_signers = false;
          }

          if (answers === true && answersLog === false) {
            this.response_doc = true;
          } else if (answersDepartament === true && answersLog === false) {
            this.response_doc = true;
          } else {
            this.response_doc = false;
          }
          if (pdfs.attachments.length >= 1) {
            this.existAttach = true;
          } else {
            this.existAttach = false;
          }

          this.DocumentDespach = pdfs;
          this.despachoPdf = this.Store.host + pdfs.complete_file;
          this.Store.pdfNow = this.despachoPdf;
          this.despachoLog = pdfs.logs;
          this.CheckEdit(pdfs);
        }
      });
      this.requiredList = DataRequiredSigners;
    },

    EditDocument () {
      this.Store.EditDocArray = this.documentos;
      if (this.DocumentDespach.to_users.length >= 1 && this.DocumentDespach.to_departments.length === 0) {
        this.Store.documentDataEdit = {
          typeDoc: this.DocumentDespach.document_type_id,
          attachments: this.DocumentDespach.attachments,
          attachments_old: this.DocumentDespach.attachments,
          subject: this.DocumentDespach.subject,
          body: this.DocumentDespach.body,
          status_sign: 0, /* need more informations */
          user_id: this.Store.user.id,
          to_users: this.DocumentDespach.to_users,
          department_id: this.DocumentDespach.author.department_id
        };
        this.Store.DocumentDespachComplete = this.DocumentDespach;
        this.Store.DocumentEditID = this.DocumentDespach.id;
        this.Store.SignersDataEdit.Signers = this.DocumentDespach.signers;
        this.Store.SecondSendEdit = false;
        this.Store.SendToDataEdit.FirstToSend = 'solo';
        this.Store.SendToDataEdit.SecondToSendType = 'share';
      }

      if (this.DocumentDespach.to_users.length >= 1 && this.DocumentDespach.to_departments.length >= 1) {
        this.Store.documentDataEdit = {
          typeDoc: this.DocumentDespach.document_type_id,
          attachments: this.DocumentDespach.attachments,
          attachments_old: this.DocumentDespach.attachments,
          subject: this.DocumentDespach.subject,
          body: this.DocumentDespach.body,
          status_sign: 0, /* need more informations */
          user_id: this.Store.user.id,
          to_users: this.DocumentDespach.to_users,
          to_departments: this.DocumentDespach.to_departments,
          department_id: this.DocumentDespach.author.department_id
        };
        this.Store.DocumentDespachComplete = this.DocumentDespach;
        this.Store.DocumentEditID = this.DocumentDespach.id;
        this.Store.SignersDataEdit.Signers = this.DocumentDespach.signers;
        this.Store.SecondSendEdit = true;
        this.Store.SendToDataEdit.FirstToSend = 'solo';
        this.Store.SendToDataEdit.SecondToSendType = 'share';
      }

      if (this.DocumentDespach.to_users.length === 0 && this.DocumentDespach.to_departments.length >= 1) {
        this.Store.documentDataEdit = {
          typeDoc: this.DocumentDespach.document_type_id,
          attachments: this.DocumentDespach.attachments,
          attachments_old: this.DocumentDespach.attachments,
          subject: this.DocumentDespach.subject,
          body: this.DocumentDespach.body,
          status_sign: 0,
          user_id: this.Store.user.id,
          to_departments: this.DocumentDespach.to_departments,
          department_id: this.DocumentDespach.author.department_id
        };
        this.Store.DocumentDespachComplete = this.DocumentDespach;
        this.Store.DocumentEditID = this.DocumentDespach.id;
        this.Store.SignersDataEdit.Signers = this.DocumentDespach.signers;
        this.Store.SecondSendEdit = false;
        this.Store.SendToDataEdit.FirstToSend = 'share';
        this.Store.SendToDataEdit.SecondToSendType = 'solo';
      }
    },

    async Get_users () {
      await this.Store.Get_users();
      this.Servidor = this.Store.users;
    },

    formatDate (value) {
      moment.locale('pt-br');
      return moment(value).format('DD/MM/YYYY HH:mm:ss');
    },

    async sendComent () {
      this.loadingComment = true;
      let despachtId = 0;
      this.documentos.forEach(depachos => {
        if (depachos.dispatch === this.despacho) {
          despachtId = depachos.id;
        }
      });
      await AxiosAPI
        .post('/api/documents/' + despachtId + '/notes', {
          description: this.textComent
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then(() => {
          this.textComent = '';
          document.getElementById('closeModal').click();
          this.Get_doc_info();
          this.loadingComment = false;
        });
    },
    printPdf (value, despacho) {
      if (value === 1) {
        const printPdf = window.open(this.Store.host + this.valueCode, '', 'width=800,height=600');
        printPdf.onload = () => {
          printPdf.print();
        };
      }

      if (value === 2) {
        let pdf = '';
        this.documentos.forEach(depachos => {
          if (depachos.dispatch === this.despacho) {
            pdf = this.Store.host + depachos.dispatch_file;
          }
        });
        const printPdf = window.open(pdf, '', 'width=800,height=600');
        printPdf.onload = () => {
          printPdf.print();
        };
      }

      if (value === 3) {
        if (despacho.file_path_signed !== null) {
          const printPdf = window.open(this.Store.host + despacho.file_path_signed, '', 'width=800,height=600');
          printPdf.onload = () => {
            printPdf.print();
          };
        }

        if (despacho.file_path_signed === null) {
          const printPdf = window.open(this.Store.host + despacho.file_path, '', 'width=800,height=600');
          printPdf.onload = () => {
            printPdf.print();
          };
        }
      }
    },

    Get_Servidor () {
      const Departs = this.Departamentos;
      const users = [];

      for (let i = 0; i < Departs.length; i++) {
        users.push(...Departs[i].users);
      }

      this.Servidor = users;

      if (this.Departamentos.length <= 0) {
        this.Servidor = this.Store.users;
      }
    },

    async SignerAttach () {
      document.getElementById('closeModalSignerAttach').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentDespach.id}/sign-leader`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.SignerAttachSucess = true;
            this.Get_doc_info();
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.wrongPasswordAttach.click();
          clearInterval(interval);
        });
    },

    async Signer () {
      document.getElementById('closeModalSigner').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentDespach.id}/sign`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.NameDocSigner = resp.data.document;
            this.SignerSucess = true;
            this.Get_doc_info();
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.wrongPassword.click();
          clearInterval(interval);
        });
    },

    async SignerWithAttach () {
      document.getElementById('SignerWithAttachcloseModal').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          console.log(resp);
          this.NameDocSigner = resp.data.document;
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.WithAttachwrongPassword.click();
          clearInterval(interval);
        });

      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign-attachment`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          console.log(resp);
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.SignerWithAttachSucess = true;
            this.Get_doc_info();
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        });
    },

    async archive () {
      this.$refs.LoadingArchivehiddens.click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      await AxiosAPI
        .post(`api/documents/${this.DocumentDespach.id}/filed`, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          console.log(resp);
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            this.$refs.hiddenButtonarchs.click();
            this.progress = 0;
            if (this.$route.name === 'Recebido') {
              this.$router.push('/' + routerpath + '/Recebidos/');
            }
            if (this.$route.name === 'EnviadosView') {
              this.$router.push('/' + routerpath + '/Enviados/');
            }
            if (this.$route.name === 'Assinaturasview') {
              this.$router.push('/' + routerpath + '/Assinaturas/');
            }
            if (this.$route.name === 'FavoritoView') {
              this.$router.push('/' + routerpath + '/Favoritos/');
            }
          }, 2000);
        });
    },

    SignerStart () {
      this.password = '';
      this.wrongPasswords = false;
    },

    DocumentSignerIds (value) {
      this.SignerStart();
      this.DocumentSignerId = value;
    }

  }
};
</script>
<style>
.p-timeline-event-marker{
  background-color: #F1860B !important;
}
.p-timeline-event-connector{
  background-color: #F1860B !important;
}
</style>
<template>

    <div v-if='Loading' class="container" style="height: 93vh !important;" id="ViewDoc">
      <div class="row d-flex justify-content-center align-items-center" >
      <div>
        <ProgressBar class="mt-5" mode="indeterminate" style="height: 6px;width:100%"></ProgressBar>
        <span class="d-flex justify-content-center align-items-center font-docs">carregando...</span>
      </div>
    </div>
  </div>
    <div v-else-if='!Loading && DocumentView' id="ViewDoc" class='py-4 container-fluid'  :style="{ minHeight: componenteHeight }">
      <Breadcrumb />
      <div v-if="this.Store.SuccessDepatch" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> Despacho concluído e enviado.  </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="this.Store.SuccessDepatch = false" />
          </div>
        </div>
        <div v-if="this.Store.editDocumentSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> Despacho editado com sucesso.  </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="this.Store.editDocumentSucess = false" />
          </div>
        </div>
        <button type="button" ref="hiddenButtons" class="btn btn-primary d-none" data-toggle="modal" data-target="#Loading"></button>
      <div v-if="SignerSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> {{NameDocSigner.protocol}} despacho - {{ NameDocSigner.dispatch }} assinado!  </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="SignerSucess = false" />
          </div>
        </div>
        <div v-if="SignerAttachSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> Anexo(os) assinado(os)!  </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="SignerAttachSucess = false" />
          </div>
        </div>
        <div v-if="SignerWithAttachSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> {{NameDocSigner.protocol}} despacho - {{ NameDocSigner.dispatch }} e anexo(os) assinado(os)! </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="SignerWithAttachSucess = false" />
          </div>
        </div>
      <div v-if="!this.Store.mobile" class="d-flex py-4">
        <div class="mr-5" style="width:75%;">
          <div class="background-pags" style="padding: 1em;border-radius: 8px;border-bottom: 1px solid #DEE2E6;">
          <div class="d-flex justify-content-between ml-2">
            <div>
              <h4 class="font-docs text-black"><b>{{ documentos[0].protocol }}</b></h4>
            </div>
            <div class="d-flex">
              <img src="../../assets/img/printer.png" class="mr-3" style="width: 25px; height: 25px; cursor:pointer" @click="printPdf(1)">
              <img src="../../assets/img/timer.png" style="width: 25px; height: 25px; cursor:pointer" data-toggle="modal" data-target="#timeline">
            </div>
          </div>
          <hr>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="mt-3 ml-2">
                <span class="font-docs"><b>Criado por</b><br/></span>
                <span class="font-docs" style="color:#6C757D">{{ documentos[0].author.user.name }}</span>
              </div>
              <div class="ml-2 mt-1">
                <span class="font-docs"><b>Data</b><br/></span>
                <span class="font-docs" style="color:#6C757D">{{ formatDate(documentos[0].created_at)}}</span>
              </div>
            </div>
            <div>
              <qrcode-vue :value="this.Store.host+valueCode" :size="sizeCode" level="H" />
            </div>
          </div>
        </div>

        <div class="background-pags mt-3" style="padding: 1em;">
          <div class="d-flex justify-content-between ml-2 mt-2">
            <div>
              <h4 v-if="documentCompleteView" class="font-docs text-black"><b>Documento completo</b></h4>
              <h4 v-if="!documentCompleteView" class="font-docs text-black"><b>Despacho {{ despacho }}</b></h4>
            </div>
            <div v-if="!documentCompleteView" class="d-flex">
              <SignerSituation :StatusSituation="DocumentDespach" class="mr-2"/>
              <SignerPen :penShow="DocumentDespach" @IdDocument-value="DocumentSignerIds" class="mr-2"/>

              <img src="../../assets/img/chat-dots.png" class="mr-3" style="width: 25px; height: 25px; cursor:pointer" data-toggle="modal" data-target="#comment">
              <div v-if="existAttach">
                <font-awesome-icon :icon="['fas', 'paperclip']" class="Color-Docs mr-3"  style="font-size: 25px;cursor:pointer" data-toggle="modal" data-target="#attachView"/>
              </div>
              <div v-if="!existAttach">
                <font-awesome-icon :icon="['fas', 'paperclip']" class="mr-3" style="font-size: 25px !important;color:#ADB5BD"/>
              </div>
              <img src="../../assets/img/printer.png" class="mr-3" style="width: 25px; height: 25px; cursor:pointer" @click="printPdf(2)">
              <img src="../../assets/img/timer.png" style="width: 25px; height: 25px; cursor:pointer" data-toggle="modal" data-target="#timelineViewDispatch">
              <font-awesome-icon :icon="['fas', 'box-archive']" class="ml-3 Color-Docs" style="width: 25px; height: 25px; cursor:pointer" data-toggle="modal" data-target="#archive"/>
            </div>
          </div>
          <hr>
            <div style="height: 100vh">
                <iframe :src="despachoPdf" height="100%" width="100%"></iframe>
            </div>
        </div>
      </div>

        <div style="width:25%;">
           <div class="background-pags">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="font-docs text-black ml-2 mt-2"><b>Despachos</b></h4>
                <div v-if="required_signers">
                  <div>
                    <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" disabled>Assinaturas obrigatórias</button>
                  </div>
                </div>
                <div v-if="response_doc && !required_signers && this.$route.name === 'Recebido'">
                <router-link :to='{ name: "DespacharRecebido" }'>
                  <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" @click="this.StoreResp.oldDoc = this.DocumentDespach; this.StoreResp.oldDocProtocol = this.documentos[0].protocol">Despachar</button>
                </router-link>
              </div>
              <div v-if="response_doc && !required_signers && this.$route.name === 'EnviadosView'">
                <router-link :to='{ name: "DespacharEnviados" }'>
                  <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" @click="this.StoreResp.oldDoc = this.DocumentDespach; this.StoreResp.oldDocProtocol = this.documentos[0].protocol">Despachar</button>
                </router-link>
              </div>
              <div v-if="response_doc && !required_signers && this.$route.name === 'Assinaturasview'">
                <router-link :to='{ name: "DespacharAssinaturas" }'>
                  <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" @click="this.StoreResp.oldDoc = this.DocumentDespach; this.StoreResp.oldDocProtocol = this.documentos[0].protocol">Despachar</button>
                </router-link>
              </div>
              <div v-if="response_doc && !required_signers && this.$route.name === 'FavoritoView'">
                <router-link :to='{ name: "DespacharFavoritos" }'>
                  <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" @click="this.StoreResp.oldDoc = this.DocumentDespach; this.StoreResp.oldDocProtocol = this.documentos[0].protocol">Despachar</button>
                </router-link>
              </div>
              </div>
              <!-- <div v-if="required_signers" class="ml-2 mb-2">
                  <div v-for="RequiredSigner in requiredList" :key="RequiredSigner" class="d-flex justify-content-between mb-2">
                    <span class="font-docs">
                      {{RequiredSigner.user.name}}
                    </span>
                    <div v-if="RequiredSigner.clicksign_status === '0'" class="d-flex align-items-center justify-content-center mr-3" style="width: 150px;height: 25px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                        <span class="font-docs font-weight-bold" style="font-size: 13px">Aguardando assinatura</span>
                    </div>
                    <div v-if="RequiredSigner.clicksign_status === '1'" class="d-flex align-items-center justify-content-center mr-3" style="width: 150px;height: 25px;background-color: #D1E7DD;color: #198754;border-radius: 10px;">
                        <span class="font-docs font-weight-bold" style="font-size: 13px">Assinado</span>
                    </div>
                  </div>
                </div>
                Verificar a utilizade disso;
                -->
            </div>

            <div class="background-pags">
              <Accordion :activeIndex="this.despacho"  @tab-open="AccordionChange">
                <AccordionTab :header="'Documento Completo'">
                  <div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="ml-2 mt-1">
                          <span class="font-docs"><b>Visibilidade</b><br/></span>
                          <span class="font-docs" style="color:#6C757D">Interna</span>
                        </div>
                      </div>
                      <div>
                        <qrcode-vue :value="this.Store.host+valueCode" :size="sizeCode" level="H" />
                      </div>
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab v-for="tab in documentos" :key="tab.dispatch" :header="'Despacho - '+ tab.dispatch">
                  <div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="mt-1 ml-2" v-if="tab.to_users.length >= 1">
                          <span class="font-docs"><b>Para servidores:</b><br/></span>
                          <span v-for="(authors, index) in tab.to_users" :key="authors" class="font-docs" style="color:#6C757D">
                            {{authors.user.name}}<span v-if="index < tab.to_users.length - 1">, </span>
                          </span>
                        </div>
                        <div class="mt-1 ml-2" v-if="tab.to_departments.length >= 1">
                          <span class="font-docs"><b>Para departamentos:</b><br/></span>
                          <span v-for="(departments, index) in tab.to_departments" :key="departments" class="font-docs" style="color:#6C757D">
                            {{ departments.department.title }}<span v-if="index < tab.to_departments.length - 1">, </span>
                          </span>
                        </div>
                        <div class="ml-2 mt-1">
                          <span class="font-docs"><b>Visibilidade</b><br/></span>
                          <span class="font-docs" style="color:#6C757D">Interna</span>
                        </div>
                      </div>
                      <div>
                        <qrcode-vue :value="this.Store.host+tab.complete_file" :size="sizeCode" level="H" />
                      </div>
                    </div>
                  </div>
                  <div v-if="tab.logs.some(note => note.type === '5')" class="mt-3">
                    <div v-for="note in tab.logs" :key="note.type">
                      <div v-if="note.type === '5'" class="d-flex align-items-start mt-3">
                        <img :src="this.Store.host+note.user.path_image" class="mr-3" style="width: 45px; height: 45px; border-radius: 50%">
                        <div class="w-100">
                          <div class="d-flex justify-content-between">
                            <div>
                              <span>{{ note.user.name }}</span>
                              <small class="d-block">{{ note.user.role }}</small>
                            </div>
                            <span>{{ formatDate(note.created_at) }}</span>
                          </div>
                          <div v-html="note.description" class="mt-2" style="color: black;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="mt-3">
                    <span>Nenhum comentario!</span>
                  </div>
                </AccordionTab>
            </Accordion>
            </div>
        </div>
      </div>

      <div v-if="this.Store.mobile" class="py-4">
        <div class="mr-5 w-100">
          <div class="background-pags" style="padding: 1em;border-radius: 8px;border-bottom: 1px solid #DEE2E6;">
          <div class="d-flex justify-content-between ml-2">
            <div>
              <h4 class="font-docs text-black"><b>{{ documentos[0].protocol }}</b></h4>
            </div>
            <div class="d-flex">
              <img src="../../assets/img/printer.png" class="mr-3" style="width: 25px; height: 25px; cursor:pointer" @click="printPdf(1)">
              <img src="../../assets/img/timer.png" style="width: 25px; height: 25px; cursor:pointer" data-toggle="modal" data-target="#timeline">
            </div>
          </div>
          <hr>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="mt-3 ml-2">
                <span class="font-docs"><b>Criado por</b><br/></span>
                <span class="font-docs" style="color:#6C757D">{{ documentos[0].author.user.name }}</span>
              </div>
              <div class="ml-2 mt-1">
                <span class="font-docs"><b>Data</b><br/></span>
                <span class="font-docs" style="color:#6C757D">{{ formatDate(documentos[0].created_at)}}</span>
              </div>
            </div>
            <div>
              <qrcode-vue :value="this.Store.host+valueCode" :size="sizeCode" level="H" />
            </div>
          </div>
        </div>

        <div class="background-pags mt-3" style="padding: 1em;">
          <div class="ml-2 mt-2">
            <div class="d-flex justify-content-between">
              <h4 class="font-docs text-black"><b>Despacho {{ despacho }}</b></h4>
              <div class="d-flex">
                  <SignerPen :penShow="DocumentDespach" @IdDocument-value="DocumentSignerIds" class="mr-2"/>
                  <img src="../../assets/img/chat-dots.png" class="mr-2" style="width: 25px; height: 25px; cursor:pointer" data-toggle="modal" data-target="#comment">
                  <div v-if="existAttach">
                  <font-awesome-icon :icon="['fas', 'paperclip']" class="Color-Docs mr-2"  style="font-size: 25px;cursor:pointer" data-toggle="modal" data-target="#attachView"/>
                  </div>
                  <div v-if="!existAttach">
                    <font-awesome-icon :icon="['fas', 'paperclip']" class="mr-2" style="font-size: 25px !important;color:#ADB5BD"/>
                  </div>
                  <img src="../../assets/img/printer.png" class="mr-2" style="width: 25px; height: 25px; cursor:pointer" @click="printPdf(2)">
                  <img src="../../assets/img/timer.png" style="width: 25px; height: 25px; cursor:pointer" data-toggle="modal" data-target="#timelineViewDispatch">
                  <font-awesome-icon :icon="['fas', 'box-archive']" class="ml-2 Color-Docs" style="width: 25px; height: 25px; cursor:pointer" data-toggle="modal" data-target="#archive"/>
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <SignerSituation :StatusSituation="DocumentDespach"/>
            </div>
          </div>
          <hr>
            <div style="height: 500px">
                <Viwerjs />
            </div>
        </div>
      </div>

        <div class="w-100">
           <div class="background-pags">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="font-docs text-black ml-2 mt-2"><b>Despachos</b></h4>
                <div v-if="response_doc && this.$route.name != 'EnviadosView' && this.$route.name != 'FavoritoView'">
                  <div v-if="response_doc && this.$route.name === 'Recebido'">
                <router-link :to='{ name: "DespacharRecebido" }'>
                  <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" @click="this.StoreResp.oldDoc = this.documentos">Despachar</button>
                </router-link>
              </div>
              <div v-if="response_doc && this.$route.name === 'EnviadosView'">
                <router-link :to='{ name: "DespacharEnviados" }'>
                  <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" @click="this.StoreResp.oldDoc = this.documentos">Despachar</button>
                </router-link>
              </div>
              <div v-if="response_doc && this.$route.name === 'Assinaturasview'">
                <router-link :to='{ name: "DespacharAssinaturas" }'>
                  <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" @click="this.StoreResp.oldDoc = this.documentos">Despachar</button>
                </router-link>
              </div>
              <div v-if="response_doc && this.$route.name === 'FavoritoView'">
                <router-link :to='{ name: "DespacharFavoritos" }'>
                  <button class="btn text-white mt-2 mr-2 label-docs" style="background-color:#F1860B" @click="this.StoreResp.oldDoc = this.documentos">Despachar</button>
                </router-link>
              </div>
              </div>
              </div>
            </div>

            <div class="background-pags">
              <Accordion :activeIndex="this.despacho"  @tab-open="AccordionChange">
                <AccordionTab :header="'Documento Completo'">
                  <div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="ml-2 mt-1">
                          <span class="font-docs"><b>Visibilidade</b><br/></span>
                          <span class="font-docs" style="color:#6C757D">Interna</span>
                        </div>
                      </div>
                      <div>
                        <qrcode-vue :value="this.Store.host+valueCode" :size="sizeCode" level="H" />
                      </div>
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab v-for="tab in documentos" :key="tab.dispatch" :header="'Despacho - '+ tab.dispatch">
                  <div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="mt-1 ml-2" v-if="tab.to_users.length >= 1">
                          <span class="font-docs"><b>Para servidores:</b><br/></span>
                          <span v-for="(authors, index) in tab.to_users" :key="authors" class="font-docs" style="color:#6C757D">
                            {{authors.user.name}}<span v-if="index < tab.to_users.length - 1">, </span>
                          </span>
                        </div>
                        <div class="mt-1 ml-2" v-if="tab.to_departments.length >= 1">
                          <span class="font-docs"><b>Para departamentos:</b><br/></span>
                          <span v-for="(departments, index) in tab.to_departments" :key="departments" class="font-docs" style="color:#6C757D">
                            {{ departments.department.title }}<span v-if="index < tab.to_departments.length - 1">, </span>
                          </span>
                        </div>
                        <div class="ml-2 mt-1">
                          <span class="font-docs"><b>Visibilidade</b><br/></span>
                          <span class="font-docs" style="color:#6C757D">Interna</span>
                        </div>
                      </div>
                      <div>
                        <qrcode-vue :value="this.Store.host+tab.complete_file" :size="sizeCode" level="H" />
                      </div>
                    </div>
                  </div>
                  <div v-if="tab.logs.some(note => note.type === '5')" class="mt-3">
                    <div v-for="note in tab.logs" :key="note.type">
                      <div v-if="note.type === '5'" class="d-flex align-items-start mt-3">
                        <img :src="this.Store.host+note.user.path_image" class="mr-3" style="width: 45px; height: 45px; border-radius: 50%">
                        <div class="w-100">
                          <div class="d-flex justify-content-between">
                            <div>
                              <span>{{ note.user.name }}</span>
                              <small class="d-block">{{ note.user.role }}</small>
                            </div>
                            <span>{{ formatDate(note.created_at) }}</span>
                          </div>
                          <div v-html="note.description" class="mt-2" style="color: black;"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="mt-3">
                    <span>Nenhum comentario!</span>
                  </div>
                </AccordionTab>
            </Accordion>
            </div>
        </div>
      </div>

      <div class="modal fade" id="comment" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Comentar</b></h4>
              <button type="button"  id="closeModal" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
              <div class="d-flex">
                <img :src="this.Store.host+this.Store.user.path_image" class="mr-3" style="width: 45px; height: 45px; border-radius: 50%">
                <textarea v-model="textComent" placeholder="Escreva aqui..." class="form-control" id="exampleFormControlTextarea1" rows="3" style="height: 86px;"></textarea>
              </div>
              <div class="d-flex justify-content-end mr-1">
                <span style="color:#6C757D">{{ textComent.length }} / 100</span>
              </div>
              <div class="d-flex justify-content-end">
                <button v-if="!loadingComment" class="btn mt-3 font-docs" style="color: #F1860B; border: 1px solid #F1860B" @click="sendComent">Comentar</button>
                <button v-if="loadingComment" class="btn mt-3 font-docs" style="color: #F1860B; border: 1px solid #F1860B" disabled>Aguarde ... <i class="fa fa-circle-o-notch fa-spin"></i></button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="archive" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Arquivar documento</b></h4>
              <button type="button"  id="closeModalarchive" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <span class="font-docs" style="font-size: 17px">o documento: <b><i>{{ documentos[0].protocol }} / Despacho - {{ despacho }}</i></b>, irá ser arquivado!</span>
                </div>
              <div>
            </div>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" data-dismiss="modal">Voltar</button>
                <button class="btn mt-3 ml-3 mr-3 font-docs text-white background-Docs" data-dismiss="modal" @click="archive()">Confirmar</button>
              </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="timeline" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Histórico completo</b></h4>
              <button type="button" id="closeModal" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
                    <Timeline :value="logsAll">
                        <template #opposite="slotProps">
                            <span class="font-docs" style="color:#465668">{{ formatDate(slotProps.item.created_at) }}</span>
                        </template>
                        <template #content="slotProps">
                          <span class="font-docs-recived-header" style="color:#465668">{{slotProps.item.user.name}}</span>
                          <div>
                            <span v-if="slotProps.item.type === '0'" class="font-docs" style="color:#465668">Criado</span>
                            <span v-if="slotProps.item.type === '1'" class="font-docs" style="color:#465668">Visualizado</span>
                            <span v-if="slotProps.item.type === '2'" class="font-docs" style="color:#465668">Assinado</span>
                            <span v-if="slotProps.item.type === '3'" class="font-docs" style="color:#465668">Despachado</span>
                            <span v-if="slotProps.item.type === '4'" class="font-docs" style="color:#465668">Criado</span>
                            <span v-if="slotProps.item.type === '5'" class="font-docs" style="color:#465668">Comentário</span>
                          </div>
                        </template>
                    </Timeline>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="timelineViewDispatch" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Histórico do despacho {{ this.despacho }}</b></h4>
              <button type="button"  id="closeModal" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
                <div class="card">
                    <Timeline :value="despachoLog">
                        <template #opposite="slotProps">
                            <span class="font-docs" style="color:#465668">{{ formatDate(slotProps.item.created_at) }}</span>
                        </template>
                        <template #content="slotProps">
                          <span class="font-docs-recived-header" style="color:#465668">{{slotProps.item.user.name}}</span>
                          <div>
                            <span v-if="slotProps.item.type === '0'" class="font-docs" style="color:#465668">Criado</span>
                            <span v-if="slotProps.item.type === '1'" class="font-docs" style="color:#465668">Visualizado</span>
                            <span v-if="slotProps.item.type === '2'" class="font-docs" style="color:#465668">Assinado</span>
                            <span v-if="slotProps.item.type === '3'" class="font-docs" style="color:#465668">Despachado</span>
                            <span v-if="slotProps.item.type === '4'" class="font-docs" style="color:#465668">Criado</span>
                            <span v-if="slotProps.item.type === '5'" class="font-docs" style="color:#465668">Comentário</span>
                          </div>
                        </template>
                    </Timeline>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <button ref="wrongPassword" class="d-none" data-toggle="modal" data-target="#Signer"></button>
      <div class="modal fade" id="Signer" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Assinar documento</b></h4>
              <button type="button" id="closeModalSigner" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
              <div>
                <label class="label-docs">Sua senha <span style="color: red">*</span></label>
                <div class="input-container">
                  <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="password" placeholder="* * * * * * *">
                  <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                  <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                </div>
                <small class="text-danger d-block ml-1" v-if="wrongPasswords"><b>Senha incorreta</b></small>
                <label class="label-docs" style="color: #6C757D">A mesma de acesso ao Smart2doc</label>
                </div>
              <div class="d-flex justify-content-end">
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B">Voltar</button>
                <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="Signer()">Assinar</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="attachView" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Anexos</b></h4>
              <button type="button" id="closeModalSigner" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
                <div class='ml-3 mt-2 mb-4'>
                  <div class='mt-3'>
                    <div v-for='(attachment, index) in this.DocumentDespach.attachments' :key='index'>
                      <div v-for='(attachmentsigners, index) in attachment.signers' :key='index'>
                        <span  v-if="index === 0" class='d-flex ml-2 Color-Docs'
                          style='height: 40px;align-items: center;'>
                          <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
                            {{attachment.name}}
                            <div class="d-flex ml-2" style="cursor:pointer" @click="printPdf(3, attachment)">
                              <font-awesome-icon :icon="['fas', 'download']" />
                              </div>
                              <div v-if="attachmentsigners.signed_at === null" class="d-flex align-items-center justify-content-center ml-2" style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                                <span class="font-docs font-weight-bold"> Aguardando assinatura</span>
                            </div>
                              <div v-if="attachmentsigners.signed_at !== null" class="d-flex align-items-center justify-content-center ml-2" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;">
                                <span class="font-docs font-weight-bold">Assinado</span>
                              </div>
                          </span>
                        </div>
                      </div>
                      <!-- botão de baixar individualmente -->
                    </div>
                </div>
                <!-- <div v-if="this.DocumentDespach.attachments.some(statusSigner => statusSigner.clicksign_status === '0') && this.DocumentDespach.author.user.departments[0].id === this.Store.user.departments[0].id && this.Store.user.is_leader" class="d-flex justify-content-end">
                  <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B">Voltar</button>
                  <button class="btn mt-3 ml-3 font-docs text-white background-Docs" data-dismiss="modal" data-toggle="modal" data-target="#SignerAttach">Assinar</button>
                </div> -->
                <div class="d-flex justify-content-end">
                <button class="btn mt-3 ml-3 font-docs text-white background-Docs" data-dismiss="modal">Voltar</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <button ref="wrongPasswordAttach" class="d-none" data-toggle="modal" data-target="#SignerAttach"></button>
      <div class="modal fade" id="SignerAttach" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Assinar anexo(os)</b></h4>
              <button type="button" id="closeModalSignerAttach" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
              <div>
                <label class="label-docs">Sua senha <span style="color: red">*</span></label>
                <div class="input-container">
                  <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="password" placeholder="* * * * * * *">
                  <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                  <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                </div>
                <small class="text-danger d-block ml-1" v-if="wrongPasswords"><b>Senha incorreta</b></small>
                <label class="label-docs" style="color: #6C757D">A mesma de acesso ao Smart2doc</label>
                </div>
              <div class="d-flex justify-content-end">
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B">Voltar</button>
                <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="SignerAttach()">Assinar</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <button ref="WithAttachwrongPassword" class="d-none" data-toggle="modal" data-target="#SignerWithAttach"></button>
      <div class="modal fade" id="SignerWithAttach" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
            <div class="d-flex justify-content-center w-100 align-items-center">
              <h4 class="modal-title font-docs text-black" id="exampleModalLongTitle"><b>Assinar documento e anexo(os)</b></h4>
              <button type="button" id="SignerWithAttachcloseModal" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            </div>
            <div class="modal-body">
              <div>
              <div>
                <label class="label-docs">Sua senha <span style="color: red">*</span></label>
                <div class="input-container">
                  <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="password" placeholder="* * * * * * *">
                  <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                  <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                </div>
                <small class="text-danger d-block ml-1" v-if="wrongPasswords"><b>Senha incorreta</b></small>
                <label class="label-docs" style="color: #6C757D">A mesma de acesso ao Smart2doc</label>
                </div>
              <div class="d-flex justify-content-end">
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B">Voltar</button>
                <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="SignerWithAttach()">Assinar</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="Loading" tabindex="-1" role="dialog" aria-labelledby="Loading" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
          <button type="button" ref="hiddenButtonCloses" class="close d-none" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <div class="modal-body">
            <span class="font-docs text-white d-flex justify-content-center">Assinando documento, Por favor, aguarde...</span>
            <div class="progress" style="height: 30px;">
              <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
              animation: progress-bar-stripes 1s linear infinite !important;
              background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
              background-size: 0.5rem 1rem;
              transition: width .6s ease;
              background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button ref="LoadingArchivehiddens" class="d-none" data-toggle="modal" data-target="#LoadingArchives"></button>
    <div class="modal fade" id="LoadingArchives" tabindex="-1" role="dialog" aria-labelledby="LoadingArchive" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
          <button type="button" ref="hiddenButtonarchs" class="close d-none" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <div class="modal-body">
            <span class="font-docs text-white d-flex justify-content-center">Arquivando documento, Por favor, aguarde...</span>
            <div class="progress" style="height: 30px;">
              <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
              animation: progress-bar-stripes 1s linear infinite !important;
              background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
              background-size: 0.5rem 1rem;
              transition: width .6s ease;
              background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  <style>
  </style>
