<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
import ModalChoseUser from './ModalChoseUser.vue';
export default {
  name: 'ModalCopy',
  components: { ModalChoseUser },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      id: 0,
      date: ''
    };
  },

  methods: {
    openModalCopy (value) {
      this.id = value;
      this.$refs.viewDocument.openModal();
    },

    copy () {
      AxiosAPI.post(`/api/tasks/${this.id}/duplicate`,
        {
          date: this.date
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.Store.accessToken}`
          }
        }).then(() => {
        this.emitter.emit('KabanUpdated');
        this.$refs.viewDocument.closeModal();
      });
    }
  }
};
</script>
<style scoped>
.dropdown-content p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
}
.dropdown-content p:hover {
  background-color: #f0f0f0;
}
</style>
<template>
    <div>
        <ModalChoseUser ref="viewDocument">
            <template #header>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                      <span class="font-docs"><b>Copiar tarefa</b></span>
                  </div>
                  <div>

                      <button
                      id="close"
                      type="button"
                      class="close"
                      @click="$refs.viewDocument.closeModal();"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>

                  </div>
                </div>
            </template>
            <template #body>
                <div>
                    <label>Informe a data que a tarefa será copiada</label>
                    <input type="date" class="my_form" v-model="date">
                </div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-end mt-4">
                  <button class="btn Color-Docs font-docs mr-3" style="border: 1px solid #F1860B" @click="$refs.viewDocument.closeModal();">
                        Cancelar
                  </button>
                  <button class="btn background-Docs text-white font-docs" @click="copy()">
                    continuar
                  </button>
                </div>
            </template>
        </ModalChoseUser>
    </div>
</template>
<style>
</style>
