<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useResponseStore, useEventStore } from '../../../stores/index';
import ProgressBarDocs from './ProgressBarResp.vue';
import Swal from 'sweetalert2';
export default {
  name: 'DashboardDefault',
  components: { ProgressBarDocs },
  setup () {
    const Main = useMainStore();
    const Store = useResponseStore();
    const Events = useEventStore();
    return {
      StoreMain: Main,
      StoreResp: Store,
      Events
    };
  },
  data () {
    return {
      LoadingPreview: false,
      typeSend: [],
      expandedRows: {},
      typeSendTwo: [],
      SecondSend: false,
      tableKeys: [],
      SelectGroup: [],
      SecondSelectGroup: [],
      FirstSelectIndividually: [],
      SecondSelectIndividually: [],
      FirstToSendGroup: [],
      FirstToSendSolo: [],
      SecondToSendGroup: [],
      FirstIndividuallySend: [],
      SecondIndividuallySend: [],
      SecondToSend: [],
      tableNoFormat: [],
      typeProfile: [{ name: 'Assinante' }, { name: 'Leitor' }],
      typeProfileSelect: [],
      checked: false,
      loading: false,
      UserDepSend: []
    };
  },
  mounted () {
    if (this.StoreResp.SendToData.FirstToSendGroup === undefined && this.StoreResp.SendToData.SecondToSendGroup === undefined && this.StoreResp.SendToData.FirstToSendSolo === undefined && this.StoreResp.SendToData.SecondToSendGroup === undefined) {
      this.Get_Servidor();
      this.GetServidoresSolo();
      this.typeSend = 'solo';
      this.UserDepSend = this.StoreMain.user.departments[0].id;
    }

    if (this.StoreResp.SendToData.FirstToSendGroup !== undefined && this.StoreResp.SendToData.FirstToSend === 'share') {
      this.tableNoFormat = this.StoreResp.tableNoFormat;
      this.tableKeys = this.StoreResp.tableKeys;
      this.FirstToSendGroup = this.StoreResp.SendToData.FirstToSendGroup;
      this.typeSend = this.StoreResp.SendToData.FirstToSend;
      this.SelectGroup = this.StoreResp.SendToData.FirstToSendOne;
      this.FirstIndividuallySend = this.StoreResp.FirstIndividuallySend;
      this.SecondIndividuallySend = this.StoreResp.SecondIndividuallySend;
      this.SecondSend = this.StoreResp.SecondSend;
      this.UserDepSend = this.StoreResp.documentData.department_id;
      if (this.StoreResp.SendToData.FirstToSend === 'share') {
        this.typeSendTwo = 'solo';
      }
    }

    if (this.StoreResp.SendToData.FirstToSendSolo !== undefined && this.StoreResp.SendToData.FirstToSend === 'solo') {
      this.tableNoFormat = this.StoreResp.tableNoFormat;
      this.tableKeys = this.StoreResp.tableKeys;
      this.FirstIndividuallySend = this.StoreResp.FirstIndividuallySend;
      this.FirstToSendSolo = this.StoreResp.SendToData.FirstToSendSolo;
      this.typeSend = this.StoreResp.SendToData.FirstToSend;
      this.FirstSelectIndividually = this.StoreResp.SendToData.FirstToSendOne;
      this.SecondIndividuallySend = this.StoreResp.SecondIndividuallySend;
      this.SecondSend = this.StoreResp.SecondSend;
      this.UserDepSend = this.StoreResp.documentData.department_id;
      if (this.StoreResp.SendToData.FirstToSend === 'solo') {
        this.typeSendTwo = 'share';
      }
    }

    if (this.StoreResp.SendToData.SecondToSendGroup !== undefined && this.StoreResp.SendToData.SecondToSendType === 'share') {
      this.tableNoFormat = this.StoreResp.tableNoFormat;
      this.tableKeys = this.StoreResp.tableKeys;
      this.SecondToSendGroup = this.StoreResp.SendToData.SecondToSendGroup;
      this.SecondSelectGroup = this.StoreResp.SendToData.SecondToSendTypeTwo;
      this.SecondSend = this.StoreResp.SecondSend;
      this.UserDepSend = this.StoreResp.documentData.department_id;
    }

    if (this.StoreResp.SendToData.SecondToSend !== undefined && this.StoreResp.SendToData.SecondToSendType === 'solo') {
      this.tableNoFormat = this.StoreResp.tableNoFormat;
      this.tableKeys = this.StoreResp.tableKeys;
      this.SecondToSend = this.StoreResp.SendToData.SecondToSend;
      this.SecondSelectIndividually = this.StoreResp.SendToData.SecondToSendTypeTwo;
      this.SecondSend = this.StoreResp.SecondSend;
      this.UserDepSend = this.StoreResp.documentData.department_id;
    }
  },
  computed: {
  },
  watch: {
    SelectGroup: function (newValue) {
      const data = [];
      this.tableNoFormat.forEach(element => {
        newValue.forEach(elementGroup => {
          if (element.id === elementGroup) {
            data.push(element);
          }
        });
      });
      this.FirstToSendGroup = data;
      this.StoreResp.SendToData.FirstToSendGroup = data;
      this.StoreResp.SendToData.FirstToSend = this.typeSend;
      this.StoreResp.SendToData.FirstToSendOne = this.SelectGroup;
    },
    SecondSelectGroup: function (newValue) {
      const data = [];
      this.tableNoFormat.forEach(element => {
        newValue.forEach(elementGroup => {
          if (element.id === elementGroup) {
            data.push(element);
          }
        });
      });
      this.SecondToSendGroup = data;
      this.StoreResp.SendToData.SecondToSendGroup = data;
      this.StoreResp.SendToData.SecondToSendType = this.typeSendTwo;
      this.StoreResp.SendToData.SecondToSendTypeTwo = this.SecondSelectGroup;
    },
    FirstSelectIndividually: function (newValue) {
      const data = [];
      this.FirstIndividuallySend.forEach(element => {
        element.children.forEach(users => {
          newValue.forEach(values => {
            if (users.value === values) {
              users.send = true;
              if (data.some(e => e.id === element.id) === false) {
                data.push(element);
              }
            }
          });
        });
      });
      this.FirstToSendSolo = data;
      this.StoreResp.SendToData.FirstToSendSolo = data;
      this.StoreResp.SendToData.FirstToSend = this.typeSend;
      this.StoreResp.SendToData.FirstToSendOne = this.FirstSelectIndividually;
    },
    SecondSelectIndividually: function (newValue) {
      const data = [];
      this.SecondIndividuallySend.forEach(element => {
        element.children.forEach(users => {
          newValue.forEach(values => {
            if (users.value === values) {
              users.send = true;
              if (data.some(e => e.id === element.id) === false) {
                data.push(element);
              }
            }
          });
        });
      });
      this.SecondToSend = data;
      this.StoreResp.SendToData.SecondToSend = data;
      this.StoreResp.SendToData.SecondToSendType = this.typeSendTwo;
      this.StoreResp.SendToData.SecondToSendTypeTwo = this.SecondSelectIndividually;
    }
  },
  methods: {
    filteredItems (items) {
      return items.children.filter(item => item.send !== false);
    },
    async nextProgress () {
      this.LoadingPreview = true;
      const arrayDocId = [];
      const ToUserSend = [];
      const inbox = [];
      if (this.typeSend === '') {
        console.log('not here');
      };
      if (this.typeSend === 'share') {
        this.FirstToSendGroup.forEach(element => {
          arrayDocId.push(element.id);
          element.children.forEach(signerUser => {
            if (signerUser.inbox === true) {
              inbox.push(signerUser.id);
            }
          });
        });

        this.StoreResp.documentData.to_departments = arrayDocId;
        this.StoreResp.documentData.inbox = inbox;
      };
      if (this.typeSend === 'solo') {
        this.FirstToSendSolo.forEach(element => {
          element.children.forEach(toSend => {
            if (toSend.send === true) {
              ToUserSend.push({
                user_id: toSend.toSendID,
                department_id: toSend.department_id
              });
            }
          });
        });
        this.StoreResp.documentData.to_users = ToUserSend;
      };
      if (this.SecondSend) {
        if (this.typeSendTwo === '') {
          console.log('not here');
        };
        if (this.typeSendTwo === 'share') {
          this.StoreResp.SendTwo = true;
          this.SecondToSendGroup.forEach(element => {
            arrayDocId.push(element.id);
            element.children.forEach(signerUser => {
              if (signerUser.inbox === true) {
                inbox.push(signerUser.id);
              }
            });
          });

          this.StoreResp.documentData.to_departments = arrayDocId;
          this.StoreResp.documentData.inbox = inbox;
        };
        if (this.typeSendTwo === 'solo') {
          this.StoreResp.SendTwo = true;
          this.SecondToSend.forEach(element => {
            element.children.forEach(toSend => {
              if (toSend.send === true) {
                ToUserSend.push({
                  user_id: toSend.toSendID,
                  department_id: toSend.department_id
                });
              }
            });
          });
          this.StoreResp.documentData.to_users = ToUserSend;
        };
      }

      if (this.StoreResp.documentData.to_users.length <= 0 && this.StoreResp.documentData.to_departments.length <= 0) {
        Swal.fire({
          title: 'Nenhum usuário ou setor selecionado!',
          text: 'Selecione para quem ou onde você deseja enviar o documento!',
          icon: 'info',
          confirmButtonText: 'Ok',
          customClass: {
            title: 'font-docs',
            htmlContainer: 'font-docs',
            confirmButton: 'font-docs'
          }
        });
        this.LoadingPreview = false;
      }

      if (this.StoreResp.documentData.to_users.length >= 1) {
        this.StoreResp.documentData.department_id = this.UserDepSend;
        this.StoreResp.progress += 1;
      }
      if (this.StoreResp.documentData.to_departments.length >= 1) {
        this.StoreResp.documentData.department_id = this.UserDepSend;
        this.StoreResp.progress += 1;
      }
    },
    backProgress () {
      this.StoreResp.progress -= 1;
    },
    async Get_Servidor () {
      await AxiosAPI.get('/api/departments/group/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.StoreMain.accessToken
        }
      })
        .then((resp) => {
          const data = [];
          for (let i = 0; i < resp.data.departments.length; i++) {
            const obj = resp.data.departments[i];

            obj.label = obj.title;
            delete obj.title;

            obj.key = obj.value;
            delete obj.value;

            obj.children = obj.users;
            delete obj.users;

            delete obj.children;
            data.push(obj);
          }
          this.tableKeys = data;
          this.StoreResp.tableKeys = data;
        });

      await AxiosAPI.get('/api/departments/group/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.StoreMain.accessToken
        }
      })
        .then((resp) => {
          const data = [];
          for (let i = 0; i < resp.data.departments.length; i++) {
            const obj = resp.data.departments[i];

            obj.label = obj.title;
            delete obj.title;

            obj.key = obj.value;
            delete obj.value;

            obj.children = obj.users;
            delete obj.users;

            for (let i = 0; i < obj.children.length; i++) {
              const childrens = obj.children[i];
              childrens.label = childrens.name;
              delete childrens.name;
              childrens.department_id = obj.id;
              childrens.inbox = false;
            }
            data.push(obj);
          }
          this.tableNoFormat = data;
          this.StoreResp.tableNoFormat = data;
        });
    },
    async GetServidoresSolo () {
      await AxiosAPI.get('/api/departments/group/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.StoreMain.accessToken
        }
      })
        .then((resp) => {
          const data = [];
          for (let i = 0; i < resp.data.departments.length; i++) {
            const obj = resp.data.departments[i];

            obj.label = obj.title;
            delete obj.title;

            obj.key = obj.value;
            delete obj.value;

            obj.children = obj.users;
            delete obj.users;
            for (let i = 0; i < obj.children.length; i++) {
              const childrens = obj.children[i];
              childrens.toSendID = childrens.id;
              delete childrens.id;
              childrens.id = childrens.value;
              childrens.department_id = obj.id;
              childrens.label = childrens.name;
              delete childrens.name;

              childrens.send = false;
            }
            data.push(obj);
          }
          this.FirstIndividuallySend = data;
          this.SecondIndividuallySend = data;
          this.StoreResp.FirstIndividuallySend = data;
          this.StoreResp.SecondIndividuallySend = data;
        });
    },
    toggleSigner (row) {
      row.signer = row.signer !== true;
    },
    toggleInbox (row) {
      row.inbox = row.inbox !== true;
    },
    inputType (value) {
      if (value === 1) {
        this.typeSendTwo = 'solo';
      }
      if (value === 2) {
        this.typeSendTwo = 'share';
      }
    },
    RemoveLabelSoloFirst (value) {
      const userRemove = value;
      this.FirstToSendSolo.forEach(element => {
        element.children.forEach(users => {
          if (users.id === userRemove.value) {
            if (users.send === true) {
              users.send = false;
            }
          }
        });
      });

      this.FirstSelectIndividually = this.FirstSelectIndividually.filter(item => item !== userRemove.value);
    },

    RemoveLabelSoloSecond (value) {
      const userRemove = value;
      this.SecondToSend.forEach(element => {
        element.children.forEach(users => {
          if (users.id === userRemove.value) {
            if (users.send === true) {
              users.send = false;
            }
          }
        });
      });

      this.SecondSelectIndividually = this.SecondSelectIndividually.filter(item => item !== userRemove.value);
    },
    removeLine (base, values) {
      if (base === 1) {
        this.FirstToSendGroup.forEach(element => {
          if (element.id === values.id) {
            this.SelectGroup = this.SelectGroup.filter(item => item !== values.id);
          }
        });
        this.FirstToSendGroup = this.FirstToSendGroup.filter(item => item !== values);
      }
      if (base === 2) {
        this.FirstToSendSolo.forEach(element => {
          element.children.forEach(users => {
            if (users.id === values.value) {
              if (users.send === true) {
                users.send = false;
              }
            }
          });
        });

        this.FirstSelectIndividually = this.FirstSelectIndividually.filter(item => item !== values.value);
      }
      if (base === 3) {
        this.SecondSelectGroup = this.SecondSelectGroup.filter(item => item !== values);
      }
      if (base === 4) {
        this.SecondToSend.forEach(element => {
          element.children.forEach(users => {
            if (users.id === values.value) {
              if (users.send === true) {
                users.send = false;
              }
            }
          });
        });

        this.SecondSelectIndividually = this.SecondSelectIndividually.filter(item => item !== values.value);
      }
    }
  }
};
</script>
<style>
.drop-zone--over {
  background-color: #d4edf7;
}
.form-check-input:checked{
  border-color: #F1860B !important;
  background-color: #F1860B !important;
  padding-bottom: 18px;
}
.p-datatable-header{
    background-color: #E9ECEF !important;
  }
</style>

<template>
  <div class="container">
    <ProgressBarDocs />
    <div v-if="LoadingPreview" class="row d-flex justify-content-center align-items-center mt-5">
      <ProgressBar class="mt-5" mode="indeterminate" style="height: 6px;width:50%"></ProgressBar>
      <span class="d-flex justify-content-center align-items-center font-docs">carregando, previsualização...</span>
    </div>
    <div v-if="!LoadingPreview" class="w-100 mt-3">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="font-docs text-docs-black"><b>Formas de envio</b></h4>
        <span class="font-docs ml-1 Color-Docs" style="cursor: pointer" @click="SecondSend = true ? true : false, this.StoreResp.SecondSend = true ? true : false"> <img class="mr-2" src="../../../assets/img/plus_box.png"> Adicionar forma de envio</span>
      </div>
      <hr>
      <div class="mb-2">
          <DataTable value='1' :responsive="true" :loading='loading'>
            <template #header>
              <div class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                <div><span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">Suas informações</span></div>
              </div>
            </template>
            <Column header='Servidor' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 20rem; font-size: 16px !important'>
                <template #body>
                  <div class="d-flex">
                    <div>
                      <img :src="StoreMain.host + StoreMain.user.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ StoreMain.user.name }} </span>
                      <span class="font-docs font-weight-bold"> {{ StoreMain.user.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column header='Cargo' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body>
                  <span>{{ StoreMain.user.role }}</span>
                </template>
              </Column>
              <Column header='Setor de envio' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body>
                  <div>
                    <select
                      id="choices-category" v-model="UserDepSend" class="my_form text-black" name="choices-category" :disabled="StoreMain.user.departments.length <= 1">
                        <option v-for="option in StoreMain.user.departments" :key="option.id" :value="option.id">
                          {{ option.title }}
                        </option>
                    </select>
                  </div>
                </template>
              </Column>
            </DataTable>
      </div>
      <div>
        <h4 class="font-docs text-docs-black ml-2"><b>Envio 1</b></h4>
        <div class="mt-4 ml-2">
           <span> Tipo <span style="color: red;">*</span></span>
        </div>
        <div class="ml-2 mt-2">
            <input type="radio" v-model="typeSend" id="share" name="share" value="share" class="" @change="inputType(1)">
            <label class="label-docs">Compartilhado</label>
            <input type="radio" v-model="typeSend" id="solo" name="solo" value="solo" class="ml-3" @change="inputType(2)">
            <label class="label-docs">Individual</label>
        </div>
        <treeselects v-if="typeSend === 'share'" v-model="SelectGroup" :multiple="true" :options="tableKeys" placeholder="Busque por nome do departamento" />
        <treeselects v-if="typeSend === 'solo'" v-model="FirstSelectIndividually" :disable-branch-nodes="true" :flat="true" :multiple="true"
        :options="FirstIndividuallySend" placeholder="Busque por nome ou nome do departamento" @deselect="RemoveLabelSoloFirst"/>
        <div v-if="FirstToSendGroup.length >= 1 && typeSend === 'share'" class="mt-3">
          <div v-for="(ChoseDep, index) in FirstToSendGroup" :key="ChoseDep">
            <DataTable :value='ChoseDep.children' :responsive="true" :loading='loading' @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
              <template #header>
                  <div class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                   <div><span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">{{FirstToSendGroup[index].label}}</span></div>
                  </div>
              </template>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 20rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
              <Column header='Enviar cópia' class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <input type="checkbox" name="copy" :id="'inbox-' + slotProps.data.inbox" v-model="slotProps.data.inbox" style="width: 16px; height: 16px" @click="toggleInbox(slotProps.data)">
                </template>
              </Column>
              <template #footer>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px;text-decoration: underline;color:#DC3545;cursor:pointer" @click="removeLine(1, FirstToSendGroup[index])">Remover setor</span>
                  </div>
              </template>
            </DataTable>
      </div>
    </div>
      <div v-if="FirstToSendSolo.length >= 1 && typeSend === 'solo'" class="mt-3">
          <div v-for="(ChoseDep, index) in FirstToSendSolo" :key="ChoseDep">
            <DataTable :value="filteredItems(ChoseDep)" :responsive="true" :loading='loading'>
              <template #header>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">{{FirstToSendSolo[index].label}}</span>
                  </div>
              </template>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
             <Column class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 2rem; font-size: 16px !important;'>
                <template #body="slotProps">
                  <div class="d-flex justify-content-end">
                  <font-awesome-icon :icon="['fas', 'trash-can']" style="cursor: pointer;font-size: 16px;" @click="removeLine(2,slotProps.data)"/>
                  </div>
                </template>
              </Column>
            </DataTable>
      </div>
      </div>
    </div>

    <div v-if="SecondSend" class="mt-5">
        <h4 class="font-docs text-docs-black ml-2"><b>Envio 2</b></h4>
        <div class="mt-4 ml-2">
           <span> Tipo <span style="color: red;">*</span></span>
        </div>
        <div class="ml-2 mt-2">
            <input type="radio" v-model="typeSendTwo" id="share" name="share" value="share" class="" disabled>
            <label class="label-docs">Compartilhado</label>
            <input type="radio" v-model="typeSendTwo" id="solo" name="solo" value="solo" class="ml-3" disabled>
            <label class="label-docs">Individual</label>
        </div>
        <treeselects v-if="typeSendTwo === 'share'" v-model="SecondSelectGroup" :multiple="true" :options="tableKeys" placeholder="Busque por nome do departamento" />
        <treeselects v-if="typeSendTwo === 'solo'" v-model="SecondSelectIndividually" :disable-branch-nodes="true" :flat="true" :multiple="true"
        :options="SecondIndividuallySend" placeholder="Busque por nome ou nome do departamento" @deselect="RemoveLabelSoloSecond"/>
        <div v-if="SecondToSendGroup.length >= 1 && typeSendTwo === 'share'" class="mt-3">
          <div v-for="(ChoseDep, index) in SecondToSendGroup" :key="ChoseDep">
            <DataTable :value='ChoseDep.children' :responsive="true" :loading='loading'>
              <template #header>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">{{SecondToSendGroup[index].label}}</span>
                  </div>
              </template>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 20rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
              <Column header='Enviar cópia' class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <input type="checkbox" name="copy" :id="'inbox-' + slotProps.data.inbox" v-model="slotProps.data.inbox" style="width: 16px; height: 16px" @click="toggleInbox(slotProps.data)">
                </template>
              </Column>
              <template #footer>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px;text-decoration: underline;color:#DC3545;cursor:pointer" @click="removeLine(3, SecondSelectGroup[index])">Remover setor</span>
                  </div>
              </template>
            </DataTable>
      </div>
    </div>
        <div v-if="SecondSelectIndividually.length >= 1 && typeSendTwo === 'solo'" class="mt-3">
          <div v-for="(ChoseDep, index) in SecondToSend" :key="ChoseDep">
            <DataTable :value="filteredItems(ChoseDep)" :responsive="true" :loading='loading'>
              <template #header>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">{{SecondToSend[index].label}}</span>
                  </div>
              </template>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
              <Column class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 2rem; font-size: 16px !important;'>
                <template #body="slotProps">
                  <div class="d-flex justify-content-end">
                  <font-awesome-icon :icon="['fas', 'trash-can']" style="cursor: pointer;font-size: 16px;" @click="removeLine(4,slotProps.data)"/>
                  </div>
                </template>
              </Column>
            </DataTable>
      </div>
      </div>
    </div>
      <div class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress()">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Avançar</button>
      </div>
    </div>
  </div>

</template>
