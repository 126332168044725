<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
import ModalComponent from './ModalComponent.vue';
import ModalResponsable from './ModalResponsable.vue';
import moment from 'moment';
import ToDoList from './ToDoList.vue';
import RequiredFill from '../Modals/RequiredFill.vue';
export default {
  name: 'ModalTask',
  components: { ModalComponent, ToDoList, ModalResponsable, RequiredFill },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      NameTask: '',
      DescriptionTask: '',
      Users: [],
      DateStart: '',
      month: '',
      week: '',
      day: '',
      place: '',
      placeLink: '',
      allDay: false,
      frequency_type: 1,
      priority: { label: 'Baixa', value: 1 },
      priorityLabels: [
        { label: 'Baixa', value: 1 },
        { label: 'Média', value: 2 },
        { label: 'Alta', value: 3 }
      ],
      status: { label: 'À iniciar', value: 1, severity: 'secondary' },
      statuses: [
        { label: 'À iniciar', value: 1, severity: 'secondary' },
        { label: 'Em andamento', value: 2, severity: 'info' },
        { label: 'Concluída', value: 3 },
        { label: 'Bloqueado', value: 4, severity: 'danger' }
      ],
      attachments: [],
      tack: [],
      hour: '',
      loading: false
    };
  },

  watch: {
    DateStart: function (value) {
      moment.locale('pt-br');
      this.day = moment(value).format('DD');
      this.week = moment(value).format('dddd');
      this.month = moment(value).format('MMMM');
    }
  },
  methods: {
    openCreateTask (value) {
      this.Store.responsableTask = this.Store.user;
      const hoje = new Date(value);
      const dia = String(hoje.getDate()).padStart(2, '0');
      const mes = String(hoje.getMonth() + 1).padStart(2, '0');
      const ano = hoje.getFullYear();
      const FormatedData = `${ano}-${mes}-${dia}`;
      this.DateStart = FormatedData;
      moment.locale('pt-br');
      this.day = moment().format('DD');
      this.week = moment().format('dddd');
      this.month = moment(FormatedData).format('MMMM');
      this.loading = false;

      this.NameTask = '';
      this.DescriptionTask = '';
      this.Users = [];
      this.place = '';
      this.placeLink = '';
      this.allDay = false;
      this.frequency_type = 1;
      this.$refs.viewDocument.openModal();
    },
    editResponsable () {
      this.$refs.modalResponsable.openModal('teste');
    },
    CreateTask () {
      this.loading = true;
      if (this.NameTask === '') {
        this.$refs.RequiredFill.openModal();
        this.loading = false;
        return;
      }
      if (this.DateStart === '') {
        this.$refs.RequiredFill.openModal();
        this.loading = false;
        return;
      }

      const files = [];
      for (let i = 0; i < this.tack.length; i++) {
        files.push(this.tack[i]);
      }
      const users = [];
      for (let i = 0; i < this.Users.length; i++) {
        users.push(this.Users[i].id);
      }
      const checklist = JSON.stringify(this.$refs.todolist.lists);
      const formData = new FormData();
      formData.append('title', this.NameTask);
      formData.append('description', this.DescriptionTask);
      formData.append('recurrence_type', this.frequency_type);
      formData.append('date', this.DateStart);
      formData.append('hour', this.hour);
      formData.append('location', this.place);
      formData.append('access_link', this.placeLink);
      formData.append('priority', this.priority.value);
      formData.append('responsible_id', this.Store.responsableTask.id);
      formData.append('situation', this.status.value);
      formData.append('users', users);
      for (let i = 0; i < this.tack.length; i++) {
        formData.append('attachments[]', this.tack[i]);
      }
      formData.append('checklists', checklist);
      AxiosAPI.post('/api/tasks', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.NameTask = '';
        this.DescriptionTask = '';
        this.Users = [];
        this.DateStart = '';
        this.hour = '';
        this.place = '';
        this.placeLink = '';
        this.priority = { label: 'Baixa', value: 1 };
        this.status = { label: 'À iniciar', value: 1, severity: 'secondary' };
        this.attachments = [];
        this.Store.responsableTask = this.Store.user;
        this.$emit('CreateTask');
        this.$refs.viewDocument.closeModal();
      });
    },

    addAttachment (event) {
      const files = Array.from(event.target.files);
      const newFiles = files.filter(file =>
        !this.tack.some(existingFile => existingFile.name === file.name)
      );

      this.attachments.push(...newFiles.map(file => ({ name: file.name })));
      this.tack.push(...newFiles);
    },
    removeAttachment (index) {
      this.attachments.splice(index, 1);
      this.tack.splice(index, 1);
      this.Store.documentData.attachments = this.tack;
      if (this.attachments.length <= 0) {
        this.signerAttach = false;
      }
    },
    openFileInput () {
      this.$refs.fileInput.click();
    }
  }
};
</script>
<style scoped>
.dropdown-content p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
}
.dropdown-content p:hover {
  background-color: #f0f0f0;
}
</style>
<template>
    <div>
      <ModalResponsable ref="modalResponsable"/>
      <RequiredFill ref="RequiredFill" />
        <ModalComponent ref="viewDocument">
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                  <div>
                      <h5 class="font-docs"><b>Nova atividade</b></h5>
                  </div>
                  <div>

                      <button
                      id="close"
                      type="button"
                      class="close"
                      @click="$refs.viewDocument.closeModal();"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>

                  </div>
                </div>
            </template>
            <template #body>
              <div>
                <div>
                  <div>
                        <div class="row">
                            <div class="col">
                                <label class="label-docs mt-3">Data <span style="color:red">*</span></label>
                                <input type="date" class="my_form" v-model="DateStart" />
                            </div>
                            <div class="col">
                                <label class="label-docs mt-3">Horario</label>
                                <input type="time" class="my_form" v-model="hour" />
                            </div>
                            <div class="col">
                                <label class="label-docs mt-3">Situação <span style="color:red">*</span></label>
                              <Dropdown
                                v-model="status"
                                :options="statuses"
                                optionLabel="label"
                                placeholder="Selecione um status"
                                class="my_form"
                                style="padding: 0rem 0.75rem !important"
                              >
                              <template #value="slotProps">
                                  <div v-if="slotProps.value != 1" class="flex align-items-center">
                                    <Tag v-if="slotProps.value.label === 'À iniciar'" rounded style='background-color: #E9ECEF;color:#6C757D;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>{{ slotProps.value.label }}</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value.label === 'Em andamento'" rounded style='background-color: #CFF4FC;color: #087990;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>{{ slotProps.value.label }}</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value.label === 'Bloqueado'" rounded style='background-color: #F8D7DA;color: #842029;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>{{ slotProps.value.label }}</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value.label === 'Concluída'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>{{ slotProps.value.label }}</b></span>
                                      </div>
                                    </Tag>
                                  </div>
                                  <span v-else>
                                    <Tag rounded style='background-color: #E9ECEF;color:#6C757D;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>{{  slotProps.placeholder }}</b></span>
                                      </div>
                                    </Tag>
                                  </span>
                              </template>
                                <template #option="{ option }">
                                <Tag v-if="option.label === 'À iniciar'" rounded style='background-color: #E9ECEF;color:#6C757D;'>
                                  <div class='flex align-items-center gap-2'>
                                    <span class='text-base font-docs'><b>{{ option.label }}</b></span>
                                  </div>
                                </Tag>
                                <Tag v-if="option.label === 'Em andamento'" rounded style='background-color: #CFF4FC;color: #087990;'>
                                  <div class='flex align-items-center gap-2'>
                                    <span class='text-base font-docs'><b>{{ option.label }}</b></span>
                                  </div>
                                </Tag>
                                <Tag v-if="option.label === 'Bloqueado'" rounded style='background-color: #F8D7DA;color: #842029;'>
                                  <div class='flex align-items-center gap-2'>
                                    <span class='text-base font-docs'><b>{{ option.label }}</b></span>
                                  </div>
                                </Tag>
                                <Tag v-if="option.label === 'Concluída'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                                  <div class='flex align-items-center gap-2'>
                                    <span class='text-base font-docs'><b>{{ option.label }}</b></span>
                                  </div>
                                </Tag>
                              </template>
                            </Dropdown>
                            </div>
                            <div class="col">
                                <label class="label-docs mt-3">Prioridade</label>

                                <Dropdown
                                v-model="priority"
                                :options="priorityLabels"
                                optionLabel="label"
                                placeholder="Selecione um status"
                                class="my_form"
                                style="padding: 0rem 0.75rem !important"
                              >
                              <template #value="slotProps">
                                  <div v-if="slotProps.value != 1" class="flex align-items-center">
                                    <div v-if="slotProps.value.label === 'Baixa'" class="d-flex">
                                    <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                                    <span>
                                      {{ slotProps.value.label }}
                                    </span>
                                    </div>

                                    <div v-if="slotProps.value.label === 'Média'" class="d-flex">
                                      <div class="d-grid mr-1">
                                        <i class="fa-solid fa-angle-up" style="color:#FFC107;position: absolute; top: 20px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                                      </div>
                                      <span>
                                        {{ slotProps.value.label }}
                                      </span>
                                    </div>

                                    <div v-if="slotProps.value.label === 'Alta'" class="d-flex">
                                      <div class="d-grid mr-1">
                                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 18px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 24px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#F76659;"></i>
                                      </div>
                                      <span>
                                        {{ slotProps.value.label }}
                                      </span>
                                    </div>
                                  </div>
                                  <span v-else>
                                    <span>
                                      {{ slotProps.placeholder }}
                                    </span>
                                  </span>
                              </template>
                                <template #option="{ option }">
                                  <div v-if="option.label === 'Baixa'" class="d-flex">
                                    <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                                    <span>
                                      {{ option.label }}
                                    </span>
                                  </div>

                                  <div v-if="option.label === 'Média'" class="d-flex">
                                    <div class="d-grid mr-1">
                                      <i class="fa-solid fa-angle-up" style="color:#FFC107;position: absolute; top: 20px;"></i>
                                      <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                                    </div>
                                    <span>
                                      {{ option.label }}
                                    </span>
                                  </div>

                                  <div v-if="option.label === 'Alta'" class="d-flex">
                                    <div class="d-grid mr-1">
                                      <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 18px;"></i>
                                      <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 24px;"></i>
                                      <i class="fa-solid fa-angle-up" style="color:#F76659;"></i>
                                    </div>
                                    <span>
                                      {{ option.label }}
                                    </span>
                                  </div>
                              </template>
                            </Dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3">
                          <div class="d-flex align-items-center">
                            <input type="checkbox" class="mr-2" v-model="allDay"/>
                            <span class="label-docs mr-1" style="width:80px">Dia inteiro</span>
                              <select class="my_form" v-model="frequency_type">
                                  <option value="1">Não se repete</option>
                                  <option value="2">Todos os dias</option>
                                  <option value="3">Semanal: toda {{ week }}</option>
                                  <option value="4">Mensal: dia {{ day }}</option>
                                  <option value="5">Anual: em {{ month }} {{ day }}</option>
                              </select>
                            </div>
                        </div>

                    <div class="mt-2">
                        <label class="label-docs">Nome <span style="color:red">*</span></label>
                        <input type="text" class="my_form" v-model="NameTask" placeholder="Nome da tarefa" />
                    </div>

                    <div>
                        <label class="label-docs mt-1">Descrição</label>
                        <textarea name="Description" id="Description" v-model="DescriptionTask" class="my_form" style="height: 86px;"></textarea>
                    </div>

                    <div class="row">
                      <div class="col">
                          <label class="label-docs mt-3">local</label>
                          <input type="text" v-model="place" class="my_form" placeholder="Meet" />
                        </div>

                        <div class="col">
                          <label class="label-docs mt-3">Link de acesso à video conferência</label>
                          <input type="text" v-model="placeLink" class="my_form" placeholder="https://meet.google.com/"/>
                        </div>
                    </div>

                    <div>
                        <ToDoList ref="todolist"/>
                    </div>

                    <div v-if="!Store.mobile" class="d-flex justify-content-between">
                      <div class="w-100">
                        <label class="label-docs mt-3">Convidados</label>
                          <MultiSelectPrime
                          display="chip"
                          v-model="Users"
                          :options="Store.users"
                          filter optionLabel="name"
                          placeholder="Selecione os envolvidos na tarefa"
                          :maxSelectedLabels="3"
                          style="z-index: 9999 !important; height: calc(3.475rem + 2px);"
                          class="w-full my_form">

                          <template #option="slotProps">
                              <div class="d-flex align-items-center">
                                  <img :alt="slotProps.option.name" :src="this.Store.host + slotProps.option.path_image" class=" mr-2" style="height: 30px; border-radius: 50%;" />
                                  <div>{{ slotProps.option.name }}</div>
                              </div>
                          </template>
                          <template #footer>
                              <div class="py-2 px-3">
                                  <b>{{ Users ? Users.length : 0 }}</b> usuário(s){{ (Users ? Users.length : 0) > 1 ? 's' : '' }} selecionados.
                              </div>
                          </template>
                          </MultiSelectPrime>
                        </div>

                        <div class="d-grid ml-5">
                          <label class="label-docs mt-3">Responsável</label>
                            <div class="d-flex align-items-center">
                              <img
                              :src="this.Store.host + this.Store.responsableTask.path_image"
                              class="mr-2"
                              style="height: 40px; border-radius: 50%;">
                              <div class="d-grid">
                                <span class="font-docs">
                                  <b>{{ this.Store.responsableTask.name }}</b>
                                </span>
                                <span class="font-docs">
                                  {{ this.Store.responsableTask.email }}
                                </span>
                              </div>
                              <i class="fa-solid fa-pen Color-Docs ml-2" style="font-size: 25px; cursor:pointer" @click="editResponsable"></i>
                            </div>
                        </div>
                    </div>

                    <div v-if="Store.mobile">
                      <div class="w-100">
                        <label class="label-docs mt-3">Convidados</label>
                          <MultiSelectPrime
                          display="chip"
                          v-model="Users"
                          :options="Store.users"
                          filter optionLabel="name"
                          placeholder="Selecione os envolvidos na tarefa"
                          :maxSelectedLabels="3"
                          style="z-index: 9999 !important; height: calc(3.475rem + 2px);"
                          class="w-full my_form">

                          <template #option="slotProps">
                              <div class="d-flex align-items-center">
                                  <img :alt="slotProps.option.name" :src="this.Store.host + slotProps.option.path_image" class=" mr-2" style="height: 30px; border-radius: 50%;" />
                                  <div>{{ slotProps.option.name }}</div>
                              </div>
                          </template>
                          <template #footer>
                              <div class="py-2 px-3">
                                  <b>{{ Users ? Users.length : 0 }}</b> usuário(s){{ (Users ? Users.length : 0) > 1 ? 's' : '' }} selecionados.
                              </div>
                          </template>
                          </MultiSelectPrime>
                        </div>

                        <div class="d-grid">
                          <label class="label-docs mt-3">Responsável</label>
                            <div class="d-flex align-items-center">
                              <img
                              :src="this.Store.host + this.Store.responsableTask.path_image"
                              class="mr-2"
                              style="height: 40px; border-radius: 50%;">
                              <div class="d-grid">
                                <span class="font-docs">
                                  <b>{{ this.Store.responsableTask.name }}</b>
                                </span>
                                <span class="font-docs">
                                  {{ this.Store.responsableTask.email }}
                                </span>
                              </div>
                              <i class="fa-solid fa-pen Color-Docs ml-2" style="font-size: 25px; cursor:pointer" @click="editResponsable"></i>
                            </div>
                        </div>
                    </div>

                    <button class="btn Color-Docs mt-5" style="border: 1px solid #F1860B" @click='openFileInput'>
                      <i class="fa-solid fa-arrow-up-from-bracket mr-2"></i>
                      <input type='file' ref='fileInput' @change='addAttachment' accept='.doc,.docx,.odt,.pdf,.png,.jpg,.svg' style='display: none' multiple/>
                      Adicionar arquivo
                    </button>
                  </div>

                  <div class='ml-3 mt-2 mb-4'>
                    <div class='mt-3'>
                      <span v-for='(attachment, index) in attachments' :key='index' class='d-flex ml-2 Color-Docs'
                        style='height: 40px;align-items: center;'>
                        <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
                          {{attachment.name}}
                            <button type='button' @click='removeAttachment(index)'
                              style='border: none;'>
                              <i class="fa fa-trash-o ml-2" aria-hidden="true" style="color: #212529"></i>
                            </button>
                        </span>
                      </div>
                  </div>
                </div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-end mt-4">
                  <button class="btn Color-Docs font-docs mr-3" style="border: 1px solid #F1860B">
                        Voltar
                  </button>
                  <button class="btn background-Docs text-white font-docs" @click="CreateTask()" :disabled="loading">
                    Cadastrar
                  </button>
                </div>
            </template>
        </ModalComponent>
    </div>
</template>
<style>
</style>
